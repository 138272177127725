import { createConfig, http } from 'wagmi';
import { anvil, bsc } from 'wagmi/chains';

export const wagmiConfig = createConfig({
  chains: [bsc, anvil],
  transports: {
    [bsc.id]: http(),
    [anvil.id]: http(),
  },
});
