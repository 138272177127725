import { Box, Typography } from '@mui/material';

const Header = () => {
  return (
    <Box sx={{ p: 2, height: '6rem', display: 'flex', justifyContent: 'center' }}>
      <Typography variant="h2">DEX Trading Bot</Typography>
    </Box>
  );
};

export default Header;
