import { Box } from '@mui/material';
import FormPropsTextFields from '@/components/Home/FormPropsTextFields';

const Home = () => {
  return (
    <Box sx={{ pt: 2, mx: '0 auto' }}>
      <FormPropsTextFields />
    </Box>
  );
};

export default Home;
