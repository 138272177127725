import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useRoutes } from 'react-router-dom';
import { CssBaseline, StyledEngineProvider, ThemeProvider, createTheme } from '@mui/material';
import { useAtom } from 'jotai';
import { WagmiProvider } from 'wagmi';
import { hashFn } from 'wagmi/query';
import { wagmiConfig } from '@/configs/wagmit';
import { ColorModeContext } from '@/contexts/index';
import routes from '@/routes';
import { darkModeAtom } from '@/states';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 1_000 * 60 * 60 * 24, // 24 hours
      queryKeyHashFn: hashFn,
    },
  },
});

function App() {
  const [darkMode, setDarkMode] = useAtom(darkModeAtom);

  const routing = useRoutes(routes);

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setDarkMode(!darkMode);
      },
    }),
    [darkMode, setDarkMode],
  );

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: darkMode ? 'dark' : 'light',
          primary: {
            main: '#FF5C00',
          },
          secondary: {
            main: '#EEEEEE',
          },
        },
      }),
    [darkMode],
  );

  return (
    <StyledEngineProvider injectFirst>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <WagmiProvider config={wagmiConfig}>
            <QueryClientProvider client={queryClient}>{routing}</QueryClientProvider>
          </WagmiProvider>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </StyledEngineProvider>
  );
}

App.whyDidYouRender = true;
export default App;
