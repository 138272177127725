import ReactLoading from 'react-loading';

const Loading = () => {
  return (
    <div className="flex h-full items-center justify-center">
      <ReactLoading type="spinningBubbles" color="#FF5C00" />
    </div>
  );
};

export default Loading;
