import { Link, Typography } from '@mui/material';

const Copyright = () => {
  return (
    <Typography
      variant="body2"
      align="center"
      sx={{
        color: 'text.secondary',
      }}
    >
      {'Copyright © '}
      <Link color="inherit" underline="hover" href="https://bot123.xyz/">
        Bot &trade;
      </Link>{' '}
      {new Date().getFullYear()}.
    </Typography>
  );
};

export default Copyright;
