import { FC, memo, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Box, Container } from '@mui/material';
import Copyright from '@/components/common/Copyright';
import Header from '@/components/common/Header';

const MainLayout: FC = (): JSX.Element => {
  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  return (
    <>
      <Container maxWidth="md" className="min-h-full">
        <Header />

        <Box sx={{ minHeight: 'calc(100vh - 7.875rem)' }}>
          <Outlet />
        </Box>

        <Copyright />
      </Container>

      <ToastContainer />
    </>
  );
};

// MainLayout.whyDidYouRender = true;
export default memo(MainLayout);
