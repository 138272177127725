import { Suspense } from 'react';
import { RouteObject } from 'react-router-dom';
import Loading from '@/components/common/Loading';
import PageNotFound from '@/components/common/PageNotFound';
import MainLayout from '@/layouts/MainLayout';
import Home from '@/pages/Home';

const Layout = () => (
  <Suspense fallback={<Loading />}>
    <MainLayout />
  </Suspense>
);

const Routes: RouteObject[] = [];

const mainRoutes = {
  path: '/',
  element: <Layout />,
  children: [
    { path: '*', element: <PageNotFound /> },
    { path: '/', element: <Home /> },
    { path: '404', element: <PageNotFound /> },
  ],
};

Routes.push(mainRoutes);
export default Routes;
