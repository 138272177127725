import { onCLS, onFCP, onINP, onLCP, onTTFB } from 'web-vitals';

const reportWebVitals = () => {
  if (process.env.NODE_ENV !== 'production') {
    onCLS(console.log);
    onINP(console.log);
    onLCP(console.log);
    onFCP(console.log);
    onTTFB(console.log);
  }
};

export default reportWebVitals;
